<template>
<section class="w-screen h-screen absolute">
  <input type="checkbox" id="hamburger" name="hamburger"/>
  <label for="hamburger"></label>
  <div class="menu-container">
    <div class="menu"></div>
    <div class="links"><a href="">Home</a><a href="">About</a><a href="">Another</a><a href="">Link</a></div>
  </div>
  <div class="content">
    <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est quisquam sit natus magni qui nemo rerum alias sunt. Nostrum incidunt officiis, vero officia laudantium assumenda voluptatibus magni unde libero reprehenderit.</p>
    <h1>Wowza</h1>
    <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veniam doloremque quidem ipsum officiis in, laboriosam soluta optio dolores nihil est, debitis beatae dolorum atque eos eum? Veniam beatae mollitia distinctio.</p>
  </div>
</section>

</template>

<script>
export default {
  name: "HamburgerMenuNr3",


  data() {

    return  {

      open : false,

    }


  },


methods: {

    openMenu(){

       this.open = !this.open

    }

}

}
</script>

<style scoped lang="scss">


.menu-container {
  position: absolute;
  width: 100%;
  overflow: hidden;
  left:0;
  top: 0;
  transition-delay: .3s;
}

.menu {
  position: relative;
  width: 15rem;
  height: 13rem;
  background-color: #1c7cd6;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  transition: all .7s;
}

#hamburger {

  display: none;
}


label {

  left: 10px;
  position: absolute;
  width: 10rem;
  height: 10rem;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 2;
  cursor: pointer;
  tap-highlight-color:transparent;

  &:before {
    content: "";
    position: absolute;
    top: 65%;
    left: 65%;
    width: 1em;
    height: 0.15em;
    background: white;
    box-shadow:
        0 0.25em 0 0 white,
        0 0.5em 0 0 white;
    transform: scale(2);
  }
}

#hamburger:checked ~ .menu-container {
  width: 30vw;
  height: 100vh;
  transition-delay: 0s;
}

#hamburger:checked ~ .menu-container .menu {
  width: 250vh;
  height: 250vh;
  transition: all .7s;
}

.menu-container .links {
  position: absolute;
  left: 15vw;
  top: 50vh;
  z-index: 3;
  transform: translate(-50%, -50%);
  text-align: center;
  a {
    display: block;
    color: white;
    font-size: 2rem;
    text-decoration: none;
    margin: 3rem;
  }
}

.content {
  padding: 3rem 10rem;
  transition: all .2s;
  transition-delay: .5s;

  h1 {
    font-size: 2rem;
    color: #212529;
  }

  h1:not(.header) {
    margin-top: 4rem;
  }

  p {
    margin-top: 2rem;
    color: #495057;
  }
}

#hamburger:checked ~ .content {
  padding-left: 35vw;
  transition: all .5s;
  transition-delay: 0s;
}

</style>